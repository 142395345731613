import '@/css/main.styl';
import { createApp } from 'vue';
import scrollToElement from 'scroll-to-element';
import Test from "@/js/components/Test.vue";
import ImageHover from "@/js/components/ImageHover.vue";
import StickyNav from "@/js/components/StickyNav.vue";
import MarqueeSlider from "@/js/components/MarqueeSlider.vue";
import ScrollAnimatedContainer from "@/js/components/ScrollAnimatedContainer.vue";
import ObfuscatedEmail from "@/js/components/ObfuscatedEmail.vue";
import Tabs from "@/js/components/Tabs.vue";
import Tab from "@/js/components/Tab.vue";
import HistorySlider from "@/js/components/HistorySlider.vue";
import MobileNavigation from "@/js/components/MobileNavigation.vue";
import CutOrderForm from "@/js/components/cutOrder/CutOrderForm.vue";
import GalleryTabs from "@/js/components/GalleryTabs.vue";
import ShuffleGallery from "@/js/components/ShuffleGallery.vue";
import SimpleGallery from "@/js/components/SimpleGallery.vue";
import OpenVideo from "@/js/components/OpenVideo.vue";
import AnimatedHeading from "@/js/components/AnimatedHeading.vue";
import SpriteAnimation from "@/js/components/SpriteAnimation.vue";
import GalleryGrid from "@/js/components/GalleryGrid.vue";
import DropdownLinker from "@/js/components/DropdownLinker.vue";
import CustomCursor from 'custom-cursor.js';

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

if(isTouchDevice()) {
    document.documentElement.classList.add('touch');
}

const app = createApp({
    components: {
        Test,
        ImageHover,
        StickyNav,
        MarqueeSlider,
        ScrollAnimatedContainer,
        ObfuscatedEmail,
        Tabs,
        Tab,
        HistorySlider,
        MobileNavigation,
        CutOrderForm,
        GalleryTabs,
        ShuffleGallery,
        SimpleGallery,
        OpenVideo,
        AnimatedHeading,
        SpriteAnimation,
        GalleryGrid,
        DropdownLinker
        // OrderPieces
    },

    data() {
        return {
            showMobileMenu: false
        }
    },
    
    mounted() {
        const options = {
            focusElements: [
                {
                    elements: 'nav a, .button, button, a.network, a.email',
                    focusClass: 'cursor--focused--nav',
                },
                // {
                //     elements: '.news-home-item',
                //     focusClass: 'cursor--focused--news',
                // },
                'a'
            ]
        }

        const cursor = new CustomCursor('.cursor', options);
        cursor.initialize();

        if(cursor.focusController) {
            cursor.focusController.addFocusElements([
                {
                    elements: '.open-video video',
                    focusClass: 'cursor--focused--video',
                    mouseenter: () => {
                        cursor.hideTrueCursor();
                    },
                    mouseleave: () => {
                        cursor.unhideTrueCursor();
                    },
                },
                {
                    elements: '.news-home-item span, .article-item .image img',
                    focusClass: 'cursor--focused--news',
                    mouseenter: () => {
                        cursor.hideTrueCursor();
                    },
                    mouseleave: () => {
                        cursor.unhideTrueCursor();
                    },
                }
            ]);
        }





    },

    methods: {
        scrollTo(e) {
            scrollToElement(e.target.hash, {
                duration: 1600,
                ease: 'inOutCube',
                offset: -100
            });
        },
        //
        // doTranslate(lang) {
        //     console.log(lang);
        //     console.log(window.doGTranslate);
        //     let xx = document.getElementById('google_translate_element2');
        //     console.log(xx);
        //     window.doGTranslate('sk|'+lang);
        //     return false;
        // }
    }
});


const vm = app.mount("#site");

console.log(import.meta.hot);
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}



