import axios from 'axios';

// const API_URL = 'https://twd.ddev.site/api';

const normalClient = axios.create({
    baseURL: window.App.baseUrl + 'api',
});

normalClient.interceptors.response.use(response => {
    return response.data.data;
});

export function makeCall(query, variables) {
    return normalClient.request({
        url: '/',
        method: 'post',
        data: {
            query,
            variables: {
                ...variables
            }
        }
    })
}
